import React from 'react';

const NoDateDialog = ({ isOpen, noDatePhotoCount, onConfirm, onCancel }) => {
  if (!isOpen) return null;

  const photoText = noDatePhotoCount === 1 
    ? "1 photo doesn't have a date" 
    : `${noDatePhotoCount} photos don't have a date`;

  return (
    <div className="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
      <div className="fixed inset-0 z-10 overflow-y-auto flex items-center justify-center">
        <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
          <div className="relative transform overflow-hidden bg-gradient-to-b from-theme-gradient-translucent-top to-theme-gradient-translucent-bottom p-[1px] rounded-lg transition-all sm:my-8 sm:w-full sm:max-w-lg">
            <div className="w-full overflow-hidden rounded-lg bg-theme-dialog-bg text-left shadow-xl transition-all">
              <div className="px-5 pt-5 pb-1">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-[#423817] sm:mx-0 sm:h-10 sm:w-10">
                    <svg className="h-6 w-6 text-[#FFCC02]" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
                    </svg>
                  </div>
                  <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <h3 className="font-satoshi font-bold text-base leading-6 text-theme-white-100" id="modal-title">
                      {photoText}
                    </h3>
                    <div className="mt-1">
                      <p className="font-satoshi font-medium text-sm text-theme-white-60">Are you sure you want to submit?</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="px-5 py-4 flex flex-col gap-2">
              <div className="flex rounded-[8px] bg-gradient-to-b from-theme-gradient-purple-top to-theme-gradient-purple-bottom p-[1px] shadow">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-theme-purple-fill px-3 py-3 text-sm font-semibold text-theme-white-100 shadow-sm hover:opacity-70"
                    onClick={() => onConfirm()}
                  >
                    Yes
                  </button>
                </div>
                <div className="flex rounded-[8px] bg-gradient-to-b from-theme-gradient-translucent-alt-top to-theme-gradient-translucent-alt-bottom p-[1px] shadow">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-theme-translucent-alt px-3 py-3 text-sm font-semibold text-theme-white-100 shadow-sm hover:opacity-70"
                    onClick={() => onCancel()}
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoDateDialog;
