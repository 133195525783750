// Helper function to format date
function formatDate(date, hideWeekday = false, hideYear = false) {
    const now = new Date();
    const oneWeekAgo = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000);
    const oneYearAgo = new Date(now.getTime() - 272 * 24 * 60 * 60 * 1000);
  
    let options = { hour: 'numeric', minute: 'numeric', hour12: !is24HourFormat() };
    const timeString = new Intl.DateTimeFormat('default', options).format(date).replace(" ", "").toLowerCase();
  
    if (date.toDateString() === now.toDateString()) {
      return `today at ${timeString}`;
    } else if (date.toDateString() === new Date(now.getTime() - 86400000).toDateString()) {
      return `yesterday at ${timeString}`;
    } else if (date > oneWeekAgo && !hideWeekday) {
      options = { weekday: 'long' };
    } else if (date > oneYearAgo || hideYear) {
      options = { month: 'short', day: 'numeric' };
    } else {
      options = { year: 'numeric', month: 'short', day: 'numeric' };
    }
  
    const dateString = new Intl.DateTimeFormat('default', options).format(date);
    return `${dateString} at ${timeString}`;
  }

 function estimatedEndDay(date) {
    const hour = date.getHours();
    if (hour > 7) {
      return date;
    }
    return new Date(date.setHours(date.getHours() - 12));
  }
  
  // Helper to check if the system uses 24-hour time
  function is24HourFormat() {
    const dateString = new Intl.DateTimeFormat('default', { hour: 'numeric' }).format(new Date());
    return !dateString.includes('AM') && !dateString.includes('PM');
  }
  
  // Main function to generate photo period text
  function generatePhotoPeriod(startDate, endDate, shotsRemaining, allowAnyDateUploads) {
    if (shotsRemaining === 0) {
      return "You've used all your camera roll shots! Tap one if you change your mind";
    }

    if (allowAnyDateUploads || !startDate) {
      return shotsRemaining === 1 ? `Select your best photo!` : `Select your best ${shotsRemaining} photos!`
    }
  
    const prefix = shotsRemaining === 1 ? "1 photo" : `Up to ${shotsRemaining} photos`;

    const now = new Date();
    if (endDate && endDate < now) {
      const hideWeekday = startDate <= new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000);
      const isInSameYear = startDate.getFullYear() === endDate.getFullYear();
      const startDateBetweenString = formatDate(startDate, false, isInSameYear);
      const endDateString = formatDate(endDate, hideWeekday);
      return `${prefix} taken between ${startDateBetweenString} and ${endDateString} will be accepted`;
    }
  
    const startDateString = formatDate(startDate);
    return `${prefix} taken after ${startDateString} will be accepted`;
  }
  
  export { formatDate, generatePhotoPeriod, estimatedEndDay };
  