import React from 'react';
import warningIcon from './images/warningIcon.png'; // Ensure the path matches where your image is stored

class UploadStatusList extends React.Component {
    render() {
        const { uploadStatus = { errors: [], message: '', completed: 0, inProgress: false }, cameraDetails = { photoPeriod: '' }, showGalleryMessage = false } = this.props;
        const className = showGalleryMessage ? "flex-grow flex items-center justify-center min-h-[150px]" : "flex-grow flex items-center justify-center";
        
        const errorsCount = Array.isArray(uploadStatus.errors) ? uploadStatus.errors.length : 0;
        const messageLength = typeof uploadStatus.message === 'string' ? uploadStatus.message.length : 0;
        const remainingErrors = errorsCount - (messageLength > 0 ? 1 : 2);
        const moreErrorsText = remainingErrors === 1 ? "+1 more error" : `+${remainingErrors} more errors`;

        return (
            <div className={className}>
                {(errorsCount === 0 && messageLength === 0 && showGalleryMessage) &&
                    <ul className="list-disc font-satoshi font-medium text-theme-offwhite-80 text-[17px] text-left w-full space-y-4 px-4">
                    <li>{cameraDetails.photoPeriod}</li>
                    <li>Photos taken with the POV Camera are shown in the gallery first</li>
                    </ul>
                }
                {(errorsCount === 0 && messageLength === 0 && !showGalleryMessage) &&
                    <p className="font-satoshi font-medium text-theme-offwhite-80 text-[14px] text-center">
                        {cameraDetails.photoPeriod}
                    </p>
                }
                {(errorsCount === 0 || uploadStatus.inProgress) && messageLength> 0 &&
                    <p className='font-satoshi font-medium text-theme-purple-text text-[14px] text-center'>
                        {uploadStatus.message}
                    </p>
                }
                {(errorsCount > 0 && !uploadStatus.inProgress) && (
                    <div className="space-y-4 pl-0.5 pr-4">
                    {(messageLength > 0 && uploadStatus.completed > 0) && (
                            <div className="flex items-center">
                                <p className="font-satoshi font-medium text-theme-offwhite-80 text-[13px] text-left ml-1 mr-[14px]">•</p>
                                <p className="font-satoshi font-medium text-theme-offwhite-80 text-[13px] text-left">
                                    {uploadStatus.message}
                                </p>
                            </div>
                        )}
                        {uploadStatus.errors.slice(0, messageLength > 0 ? 1 : 2).map((error, index) => (
                            <div key={index} className="flex items-start">
                                <img src={warningIcon} alt="Warning" className="w-[14px] h-[18px] mr-[9px] pt-[1px]" />
                                <p className="font-satoshi font-medium text-theme-offwhite-80 text-[13px] text-left">
                                    {error}
                                </p>
                                {Array.isArray(uploadStatus.errors) && index === (uploadStatus.errors.slice(0, messageLength > 0 ? 1 : 2).length - 1) && remainingErrors > 0 && 
                                    <p className="font-satoshi font-medium text-theme-offwhite-80 text-[13px] ml-1">{moreErrorsText}</p>
                                }
                            </div>
                        ))}
                    </div>
                )}
            </div>
        );
    }
}

export default UploadStatusList;
