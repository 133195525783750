import React from 'react';

const LogoutIcon = ({ fill }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.12598 17.4097C3.43262 17.4097 2.91016 17.2314 2.55859 16.875C2.20703 16.5234 2.03125 15.9961 2.03125 15.293V4.7168C2.03125 4.01367 2.20703 3.48633 2.55859 3.13477C2.91016 2.77832 3.43262 2.6001 4.12598 2.6001H11.5015C12.1997 2.6001 12.7222 2.77832 13.0688 3.13477C13.4204 3.48633 13.5962 4.01367 13.5962 4.7168V8.20312H12.5854V4.73877C12.5854 4.37744 12.4902 4.10156 12.2998 3.91113C12.1094 3.71582 11.8262 3.61816 11.4502 3.61816H4.17725C3.80615 3.61816 3.52539 3.71582 3.33496 3.91113C3.14453 4.10156 3.04932 4.37744 3.04932 4.73877V15.271C3.04932 15.6372 3.14453 15.9155 3.33496 16.106C3.52539 16.3013 3.80615 16.3989 4.17725 16.3989H11.4502C11.8262 16.3989 12.1094 16.3013 12.2998 16.106C12.4902 15.9155 12.5854 15.6372 12.5854 15.271V11.7993H13.5962V15.293C13.5962 15.9961 13.4204 16.5234 13.0688 16.875C12.7222 17.2314 12.1997 17.4097 11.5015 17.4097H4.12598ZM8.30811 10.5029C8.17627 10.5029 8.06152 10.4541 7.96387 10.3564C7.86621 10.2539 7.81738 10.1343 7.81738 9.99756C7.81738 9.86084 7.86621 9.74365 7.96387 9.646C8.06152 9.54834 8.17627 9.49951 8.30811 9.49951H15.5518L16.5771 9.54346L16.0278 9.00879L14.8706 7.91016C14.7681 7.81738 14.7168 7.7002 14.7168 7.55859C14.7168 7.42676 14.7583 7.31934 14.8413 7.23633C14.9292 7.15332 15.0366 7.11182 15.1636 7.11182C15.2808 7.11182 15.3882 7.16064 15.4858 7.2583L17.8003 9.63867C17.8589 9.69727 17.9004 9.75586 17.9248 9.81445C17.9492 9.87305 17.9614 9.93408 17.9614 9.99756C17.9614 10.061 17.9492 10.1221 17.9248 10.1807C17.9004 10.2393 17.8589 10.3003 17.8003 10.3638L15.4858 12.7368C15.3882 12.8394 15.2808 12.8906 15.1636 12.8906C15.0366 12.8906 14.9292 12.8467 14.8413 12.7588C14.7583 12.6709 14.7168 12.561 14.7168 12.4292C14.7168 12.2925 14.7681 12.1777 14.8706 12.085L16.0278 10.9863L16.5771 10.459L15.5518 10.5029H8.30811Z" fill={fill}/>
    </svg>
  );
};

export default LogoutIcon;
